<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Type</label>
                          <v-select
                            v-model="type"
                            placeholder="None"
                            :options="['Summary', 'Detailed']"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Ledger Name</label>
                          <v-select
                            v-model="ledger"
                            placeholder="None"
                            label="name"
                            :options="ledgerOptions"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Customer Name</label>
                          <v-select
                            v-model="client"
                            placeholder="None"
                            label="fullname"
                            :options="customerOptions"
                          >
                          <template #option="{ fullname, profile_image, username }">
                            <b-avatar :src="getprofileImage(profile_image)" />
                            <span class="font-weight-bolder"> {{ fullname}}</span><span>({{ username }})</span>
                          </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            style="background-color: transparent"
                            @input="getdata($event)"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="type == 'Summary'?data:detaileddata" :columns="type == 'Summary'?fields:detailed" >
                <template #printHeader>
                    <div style="display:flex;flex-direction: column;align-items:center;width:100%;">
                      <h3 style="margin:0px">Receipt Report</h3>
                      <h4 style="margin:0px">{{ type }}</h4>
                    </div>
                    <div style="display:flex;justify-content:center;width:100%;flex-direction:column;align-items:center">
             <h5 style="margin:0px" v-if="result && result.length==2">{{result[0]}} to {{result[1]}}</h5>
                    </div>
                  </template>
              </report-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import ReportTable from "../../../components/ReportTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BAvatar,
    ReportTable,
  },
  data() {
    return {
      data: "",
      ledger: "",
      client: "",
      vouchertype: "",
      startdate: "",
      enddate: "",
      result: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      data1: [],
      detaileddata:[],
      fields: [
        {
          field: "receiptdate",
          label: "Payment date",
        },
        {
          field: "customer",
          label: "Customer Name",
        },
        {
          field: "ledger",
          label: "Ledger Name",
        },
        {
          field: "project",
          label: "Project Name",
        },
        {
          field: "bankname",
          label: "Bank Name",
        },
        {
          field: "branchname",
          label: "Branch Name",
        },
        {
          field: "chequeno",
          label: "Cheque No",
        },
        {
          field: "chequedate",
          label: "Cheque Date",
        },
        {
          field: "remarks",
          label: "Remarks",
        },
        {
          field: "totalamount",
          label: "Total Amount",
        },
      ],
      ledgerOptions: [],
      customerOptions: [],
      type:'Summary',
      detailed:[]
    };
  },
  mounted() {
    this.detailed=[...this.fields]
    this.detailed.splice(4,0,
      {
          field: "plot",
          label: "Plot",
        }
    )
    this.getCustomer();
    this.getLedgers();
  },
  methods: {
    async getCustomer() {
      this.customerOptions = this.$store.state.app.all_users.filter((item)=> item.type == 'customer')
    },
    async getLedgers(){
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getLedger`,
      }).then((response) => {
        this.ledgerOptions = response.data.data;
      });
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData(tableData) {
      const data = {
        client: this.client?this.client.id:'',
        ledger: this.ledger?this.ledger.id:'',
        startdate: this.startdate,
        enddate: this.enddate,
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/receiptReport`,
      }).then((response) => {
        this.data = response.data.data;
        this.detaileddata=[];
        this.data.map((item) => {
          item.chequedate =
            item.chequedate == false || item.chequedate == "00/00/0000"
              ? "-"
              : item.chequedate;
            item.customer = item.clientname ? item.clientname.fullname:''
            item.ledger = item.ledgername ? item.ledgername.name:''
            item.project = item.project ? item.project.projectname:''


            let arr=  item.receipt_items.map((inner)=>{
              inner.plot = inner.plot_data?inner.plot_data.plotno:''
              inner.totalamount = inner.amount
              return  {...item,...inner}
            })
            this.detaileddata=[...this.detaileddata,...arr]

        });

      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
